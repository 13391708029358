import React from "react"
import { graphql } from "gatsby"
import classNames from "classnames/bind"
import styles from "./PostTemplate.module.scss"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import LayoutContainer from "../components/Common/LayoutContainer"
import ExternalLinkSvg from "../icons/ExternalLinkSvg"
import authors from "../../content/authors.yml"
import BlobShape from "../components/PostTemplate/BlobShape"

const cx = classNames.bind(styles)

export const pageQuery = graphql`
  query PostQuery($slug: String!) {
    site {
      siteMetadata {
        title
        description
      }
    }
    markdownRemark(frontmatter: { path: { eq: $slug } }) {
      frontmatter {
        title
        path
        date(formatString: "YYYY년 MM월 DD일")
        image
        tags
      }
      html
      timeToRead
      tableOfContents(absolute: false, maxDepth: 2)
    }
  }
`

const PostTemplate = ({ data, location }) => {
  const { frontmatter, html, timeToRead, tableOfContents } = data.markdownRemark

  return (
    <Layout location={location}>
      <SEO title={frontmatter.title} />

      <main className="w100">
        <LayoutContainer>
          <div className={cx("sectionImgWrapper")}>
            <img
              src={frontmatter.image}
              width="100%"
              className={cx("sectionImg")}
            />
          </div>
          <div className={cx("sectionTitleWrapper")}>
            <div className={cx("titleWrapperInner")}>
              <h1 className={cx("title")}>{frontmatter.title}</h1>
              <div className={cx("metadata")}>
                <span className={cx("metaDate")}>{frontmatter.date}</span>
              </div>
            </div>
          </div>
        </LayoutContainer>

        <LayoutContainer>
          <div className={cx("articleArea")}>
            <aside className={cx("asideLeft")}>
              {/* <div className={cx("shareButtons")}>
                <div className={cx("shareText")}>공유하기</div>
                <div className={cx("icon")}>
                  <a
                    href={`https://www.twitter.com`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ExternalLinkSvg />
                  </a>
                </div>
              </div> */}
              {/* <div className={cx("leftbox")}></div> */}
            </aside>
            <article className={cx("article")}>
              <div
                className="markdown"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </article>
            <aside className={cx("asideRight")}>
              <div dangerouslySetInnerHTML={{ __html: tableOfContents }} />
            </aside>
          </div>
        </LayoutContainer>
      </main>
    </Layout>
  )
}

export default PostTemplate
